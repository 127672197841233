<template>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="p-mb-4">
					<template v-slot:left>
						<!-- <Button label="Nuevo Pais..." icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew" /> -->
						<!-- <Button label="Eliminar" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedPaises || !selectedPaises.length" /> -->
						<!-- <Button label="Exportar a Excel"  class="p-button-warning  p-mr-2" v-on:click="download" /> -->
					</template>
				</Toolbar>

				<DataTable ref="dt" :value="ConsultaDoc" v-model:selection="selectedConsulta" dataKey="id" :paginator="true" :rows="10" :filters="filters"
                            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                            currentPageReportTemplate="Mostrando {first} al {last} de {totalRecords} paises">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Portal para consulta de muestras</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>

					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<Column field="id_muestra" header="Muestra" :sortable="true"></Column>
					<Column field="NombreDoc" header="Doctor" :sortable="true"></Column>
					<Column field="pacienteN" header="Paciente" :sortable="true"></Column>
                    <Column field="fecha_solicitud" header="Fecha Solicitud a SGCells" :sortable="true">	
						<template #body="slotProps">
							<div  >
								{{computedDateMX(slotProps.data.fecha_solicitud)}}
							</div>
						</template>
					</Column>
					 <Column field="estatu" header="Estatus" :sortable="true">	</Column>
					  <Column field="situacion" header="Situación Financiera" :sortable="true">	</Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="editConsulta(slotProps.data)" />
							<!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeletePais(slotProps.data)" /> -->
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="ConsultaDialog" :style="{width: '950px'}" header="Consulta" :modal="true" class="p-fluid">

                    <div class="p-field">
						<div class="p-formgrid p-grid">
							<div class="p-field p-col-12">
								<label for="clave">Doctor: {{dataConsulta.NombreDoc}}</label>								 
							</div>
							<div class="p-field p-col">
								<label for="clave">Muestra: {{dataConsulta.id_muestra}}</label>								 
							</div>
							<div class="p-field p-col">
								<label for="contrasena">Paciente: {{dataConsulta.pacienteN}}</label>								 
							</div>
							<div class="p-field p-col">
								<label for="contrasena">Fecha de Solicitud : {{computedDateMX(dataConsulta.fecha_solicitud)}}</label>								 
							</div>
							<div class="p-field p-col">
								<label for="contrasena">Estatus : </label> <label for="contrasena" style="font-weight:700">{{dataConsulta.estatu}}</label>								 
							</div>
						</div>
					</div>
					<div class="p-field">
						<div class="p-formgrid p-grid">
							<DataTable ref="dt" :value="ServiciosSelect"   dataKey="nombre" :paginator="false">
								<Column field="servicio" header="Servicio" :sortable="true"></Column>
								<Column field="precio" header="Precio" :sortable="true">
									<template #body="slotProps">
										<div :class="preciostyle" style="text-align: right">
											${{formatCurrency(slotProps.data.precio)}}
										</div>
									</template>
								</Column>									 
							</DataTable>
							
						</div>
						<div style="text-align: right;">
							<label >Pagos realizados :  ${{formatCurrency(PagosRealizados)}}</label>	
						</div>	
						<div style="text-align: right;">
							<label >Total :  ${{formatCurrency(Totals-PagosRealizados)}}</label>	
						</div>							
					</div>		
					<div class="p-field">
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="clave">Fecha DESEADA de Toma de muestra : {{computedDateMX(dataConsulta.fecha_deseada_toma)}}</label>								 
							</div>
						</div>
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="clave">Fecha REAL de Toma de muestra  : {{computedDateMX(dataConsulta.fecha_real_toma)}}</label>								 
							</div>
						</div>
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="clave">Fecha – 1 Envío de Células : {{computedDateMX(FechaEnvio1)}}</label>								 
							</div>
						</div>
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="clave">Fecha – 2 Envío de Células : {{computedDateMX(FechaEnvio2)}}</label>								 
							</div>
						</div>
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="clave">Fecha – 3 Envío de Células : {{computedDateMX(FechaEnvio3)}}</label>								 
							</div>
						</div>
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="clave">Fecha – 4 Envío de Células : {{computedDateMX(FechaEnvio4)}}</label>								 
							</div>
						</div>
						<div class="p-formgrid p-grid">
							<div class="p-field p-col">
								<label for="clave">Situación Financiera :</label> <label for="clave" style="font-weight:700">{{dataConsulta.situacion}}</label>								 
							</div>
						</div>
					</div>			
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="hideDialog"/> 
					</template>
				</Dialog>


			
			</div>
		</div>
	</div>

</template>

<script>
import MuestraService from '../service/MuestraService';
import XLSX from 'xlsx'
import moment from 'moment';

export default {
	data() {
		return {
			
			ConsultaDialog: false,
			deletePaisDialog: false,
			deletePaisesDialog: false,
			pais: {},
			selectedConsulta: null,
			filters: {},
			submitted: false,
			PaisExpor:null,
			ConsultaDoc:null,
			ServiciosM:null,
			dataConsulta:null,
			ServiciosSelect:[],
			Totals:0,
			EnvioCelula:null,
			PagoF:null,
			FechaEnvio1:null,
			FechaEnvio2:null,
			FechaEnvio3:null,
			FechaEnvio4:null,
			PagosRealizados:null,
		}
	},
	muestraService: null,
	situacionfinanciera: null,
	created() {
		this.muestraService = new MuestraService();
	},
	mounted() {
		this.muestraService.getMuestrasConsultaDoctor().then(data => {
			this.ConsultaDoc = data.muestras;			 
			this.ServiciosM = data.servicios;
			this.EnvioCelula = data.enviocelulas;
			this.PagoF = data.pago;			
			for(var i in this.ConsultaDoc){
				console.log(this.PagoF);
				var Nombre=this.ConsultaDoc[i].paciente;
                  this.ConsultaDoc[i].pacienteN=Nombre+" "+data.muestras[i].pacienteApP+" "+data.muestras[i].pacienteApM;
                	this.ConsultaDoc[i].situacion='Pendiente de Pago';
					
					
					
					if(this.ConsultaDoc[i].id_estatus ==1 || this.ConsultaDoc[i].id_estatus==2)
					{
						this.ConsultaDoc[i].situacion='Al Corriente';
						for(var ir1 in data.pagoservicio)
						{
							if(this.ConsultaDoc[i].id_muestra == data.pagoservicio[ir1].id_muestra)
							{
								var servtotal1=data.pagoservicio[ir1].monto;
									
								var finan1=0;

								this.ConsultaDoc[i].pago=finan1;
								this.ConsultaDoc[i].saldo=servtotal1-finan1;			

								if(data.pago.length ==0)
								{
									this.ConsultaDoc[i].pago=finan1;
									this.ConsultaDoc[i].saldo=servtotal1-finan1;	

								}else
								{							
									for(var ij1 in data.pago)
									{
										finan1=0;
										finan1=data.pago[ij1].monto;
										
										if(this.ConsultaDoc[i].id_muestra == data.pago[ij1].id_muestra)
										{									
											this.ConsultaDoc[i].pago=finan1;
											this.ConsultaDoc[i].saldo=servtotal1-finan1;		
											 
										
											if(servtotal1== finan1)
											{
												this.ConsultaDoc[i].situacion='Pagado';
											}										
																	 
										} 	
									else
									{
										
										this.ConsultaDoc[i].pago=finan1;
										this.ConsultaDoc[i].saldo=servtotal1-finan1;
										
									}								 
										
								}
							}
						}
					}

				}
				else
				{			
					for(var ir in data.pagoservicio)
						{
							if(this.ConsultaDoc[i].id_muestra == data.pagoservicio[ir].id_muestra)
							{
								var servtotal=data.pagoservicio[ir].monto;
								
								var finan=0;

								this.ConsultaDoc[i].pago=finan;
								this.ConsultaDoc[i].saldo=servtotal-finan;

								if(data.pago.length ==0)
								{
									this.ConsultaDoc[i].pago=finan;
									this.ConsultaDoc[i].saldo=servtotal-finan;	
									this.ConsultaDoc[i].situacion='Vencido';

								}else
								{
									for(var ij in data.pago)
									{
										if(this.ConsultaDoc[i].id_muestra == data.pago[ij].id_muestra)
										{
											finan=data.pago[ij].monto;
											// this.financiera[i].pago=finan;

											if(Number(servtotal)== Number(finan))
											{
												this.ConsultaDoc[i].situacion='Pagado';
											}else
											{
												this.ConsultaDoc[i].situacion='Vencido';
											}
										}else
										{
											this.ConsultaDoc[i].situacion='Vencido';
										}
										this.ConsultaDoc[i].pago=finan;
										this.ConsultaDoc[i].saldo=servtotal-finan;
									}
								}
							}
						}

					}								 
				}

					
				
				
			});
	},
	methods: {
		openNew() {
			this.pais = {};
			this.submitted = false;
			this.paisDialog = true;
			this.pais.activo=true;
		},
		hideDialog() {
			this.ConsultaDialog = false;
			this.submitted = false;
			this.Totals=0;
			this.dataConsulta=null;
			this.ServiciosSelect=[];
		},
		editConsulta(ConsultaDoc) {
			 
			this.FechaEnvio1=null;
			this.FechaEnvio2=null;
			this.FechaEnvio3=null;
			this.FechaEnvio4=null;
			this.Totals=0;
			this.dataConsulta=null;
			this.ServiciosSelect=[];
			this.dataConsulta = ConsultaDoc;
			this.ConsultaDialog = true;

			this.PagosRealizados=0;

			for(var i in this.ServiciosM){
				
				if(this.dataConsulta.id_muestra == this.ServiciosM[i].id_muestra){

					this.Totals=this.Totals+Number(this.ServiciosM[i].precio);

					this.ServiciosSelect.push(this.ServiciosM[i]);
				}
			}

			for(var iv in this.PagoF){
				
				if(this.dataConsulta.id_muestra == this.PagoF[iv].id_muestra){

					this.PagosRealizados=this.PagosRealizados+Number(this.PagoF[iv].monto); 
				}
			}

			for(var hi in this.EnvioCelula){
				
				if(this.dataConsulta.id_muestra == this.EnvioCelula[hi].id_muestra){
 
					if(this.EnvioCelula[hi].no_envio ==1){
						this.FechaEnvio1=this.EnvioCelula[hi].fecha_envio;
					}
					if(this.EnvioCelula[hi].no_envio ==2){
						this.FechaEnvio2=this.EnvioCelula[hi].fecha_envio;
					}
					if(this.EnvioCelula[hi].no_envio ==3){
						this.FechaEnvio3=this.EnvioCelula[hi].fecha_envio;
					}
					if(this.EnvioCelula[hi].no_envio ==4){
						this.FechaEnvio4=this.EnvioCelula[hi].fecha_envio;
					}
				}
			}
			console.log(this.FechaEnvio1);
			 
		 
		},
 		findIndexById(id) {
			let index = -1;
			for (let i = 0; i < this.paises.length; i++) {
				if (this.paises[i].id === id) {
					index = i;
					break;
				}
			}

			return index;
		},
		createId() {
            let id = this.paises.length + 1;
            /*
			var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			for ( var i = 0; i < 5; i++ ) {
				id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            */
			return id;
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		},		
		download : function() {
			this.paisService.getPaisesExport().then(data => {
			this.PaisExpor = data
				if(this.PaisExpor != null){
				const data = XLSX.utils.json_to_sheet(this.PaisExpor)
				const wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, data, 'data')
				XLSX.writeFile(wb,'CatalogoPais.xlsx')
			}
			});
		},
		 formatCurrency(value) {
            // return value.toLocaleString('en-US', {style: 'currency',currency: 'USD'});
            let val = (value/1).toFixed(2).replace(',', '.')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        },
		computedDateMX(value) {
			if(value == null)
			{
				return "";
			}else{
                return moment(value).format('DD-MM-YYYY')
			}
		},
		
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.pais-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .pais-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.pais-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-activo {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-inactivo {
		background: #FFCDD2;
		color: #C63737;
	}
}
</style>
